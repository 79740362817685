<template>
  <div>
    <div class="background-area">
      <img src="../../assets/banner-3.jpg" alt="banner-3">
      <div class="caption">
        <h1>南棠ERP-Listing管理</h1>
      </div>
    </div>

    <h1>南棠ERP-Listing</h1>

    <div>
      <img src="../../assets/function-intro/listing/listing.png" alt="listing">
    </div>

    <Footer/>
  </div>
</template>

<script>
import Footer from "../../components/footer/footer"
export default {
  name: "Listing",
  components: {
    Footer
  }
}
</script>

<style scoped>
  .background-area {
    position: relative;
  }
  .caption {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 5px 10px;
    color: white;
  }
</style>
